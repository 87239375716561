<template>
    <div class="bind_container">
        <div class="form_warpper">
            <div class="form_box">
                <div class="tit">
                    <p>系统已升级，请完善信息后登录。绑定
                        <span v-if="user.isEmail === 'Y'">“邮箱”</span>
                        <span v-if="user.isPhone === 'Y'">“手机”</span>
                        后，可以直接使用
                        <span v-if="user.isEmail === 'Y'">“邮箱”</span>
                        <span v-if="user.isPhone === 'Y'">“手机”</span>
                        作为登录账号。
                    </p>
                </div>
                <div class="step_row">
                    <el-steps align-center finish-status="success">
                        <el-step v-for="item in list" :key="item" :title="item.name" :status="item.status" />
                    </el-steps>
                </div>
                <!-- 邮箱表单 -->
                <el-form ref="emailFormRef" :model="emailFrom" :rules="emailRules" size="large" label-width="95px"
                    v-if="processName === '绑定邮箱'">
                    <el-form-item label="邮箱" prop="email">
                        <el-input placeholder="请输入您的邮箱" v-model="emailFrom.email" />
                    </el-form-item>
                    <el-form-item label="邮箱验证码" prop="emailCode">
                        <el-input placeholder="请输入验证码" v-model="emailFrom.emailCode">
                            <template #append>
                                <el-button :disabled="disableTimeEmail > 0" @click="sendCodeEmail">
                                    <template #default v-if="disableTimeEmail > 0">{{ disableTimeEmail }}秒</template>
                                    <template #default v-else>发送验证码</template>
                                </el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <div class="btn">
                        <el-button size="large" @click="submitEmailForm(emailFormRef)">绑定 </el-button>
                    </div>
                </el-form>
                <!-- 手机号表单 -->
                <el-form ref="phoneFormRef" :model="phoneFrom" :rules="phoneRules" size="large" label-width="95px"
                    v-if="processName === '绑定手机'">
                    <el-form-item label="手机号" prop="phone">
                        <el-input type="number" :maxlength="11" placeholder="请输入您的手机号" v-model="phoneFrom.phone" />
                    </el-form-item>
                    <el-form-item label="手机验证码" prop="phoneCode">
                        <el-input :maxlength="6" placeholder="请输入验证码" v-model="phoneFrom.phoneCode">
                            <template #append>
                                <el-button :disabled="disableTimePhone > 0" @click="sendCodePhone">
                                    <template #default v-if="disableTimePhone > 0">{{ disableTimePhone }}秒</template>
                                    <template #default v-else>发送验证码</template>
                                </el-button>
                            </template></el-input>
                    </el-form-item>
                    <div class="btn">
                        <el-button size="large" @click="submitPhoneForm(phoneFormRef)">绑定 </el-button>
                    </div>
                </el-form>
                <div class="btn" v-if="processName === '绑定完成'">
                    <el-button size="large" @click="backToHome">返回首页</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { apiUserGetUserBindCont } from '@/request/api';
import useBindEmail from './useBindEmail';
import useBindPhone from './useBindPhone';
import { useRouter } from 'vue-router';
import { removeStorage } from '@/utils';

const $router = useRouter();

let user = ref({});// 用户信息
const list = computed(() => {
    const res = [];
    if (user.value.isEmail === 'Y') {
        const obj = { name: '绑定邮箱', status: 'process' };
        if (user.value.email) obj.status = 'finish';
        res.push(obj);
    }
    if (user.value.isPhone === 'Y') {
        const obj = { name: '绑定手机', status: 'wait' };
        if (user.value.isEmail === 'N' || user.value.email) obj.status = 'process';
        if (user.value.phone) obj.status = 'finish';
        res.push(obj);
    }
    if (user.value.isEmail === 'Y' || user.value.isPhone === 'Y') {
        const obj = { name: '绑定完成', status: 'wait' };
        if (res.every(item => item.status === 'finish')) obj.status = 'finish';
        res.push(obj);
    }
    return res;
})

// 进行中的步骤名称
const processName = computed(() => {
    let res = '绑定完成';
    const idx = list.value.findIndex(item => item.status === 'process');
    if (idx !== -1) {
        res = list.value[idx].name;
    }
    return res;
})

// 获取用户
async function getUser() {
    const { data } = await apiUserGetUserBindCont();
    user.value = data || {};
    if (processName.value === '绑定完成') {
        removeStorage('passport_code');
        removeStorage('passport_msg');
    }
}

// 绑定邮箱
const { formRef: emailFormRef, form: emailFrom, rules: emailRules, submitForm: submitEmailForm, disableTime: disableTimeEmail, sendCode: sendCodeEmail } = useBindEmail(getUser, processName);

// 绑定手机号
const { formRef: phoneFormRef, form: phoneFrom, rules: phoneRules, submitForm: submitPhoneForm, disableTime: disableTimePhone, sendCode: sendCodePhone } = useBindPhone(getUser, processName);

// 返回首页
function backToHome() {
    $router.replace('/');
}

getUser();
</script>

<style lang="scss" scoped>
.bind_container {
    height: calc(100vh - var(--home-header-height));
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 33px 60px;

    .form_warpper {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .form_box {
            .tit {
                padding-bottom: 80px;
                text-align: center;

                p {
                    font-size: 16px;
                    color: #070A16;

                    span {
                        font-size: inherit;
                    }
                }
            }

            .step_row {
                text-align: center;
                padding-bottom: 48px;
            }

            .btn {
                text-align: center;
                padding-top: 40px;

                .el-button {
                    width: 320px;
                    border-radius: 8px;
                    color: #fff;
                    letter-spacing: 10px;
                    font-size: 16px;
                    background: linear-gradient(-63.43deg, rgba(0, 149, 212, 1) 0%, rgba(0, 92, 169, 1) 100%);
                }
            }
        }
    }
}
</style>