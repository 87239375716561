import { reactive, ref } from 'vue';
import { validatePhone } from '@/utils/validate';
import { ElMessage } from 'element-plus';
import withLoading from '@/utils/loading';
import { sendMessageSms, apiUserUpdateUserContactForm } from '@/request/api';

const validatePhonelRule = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入手机号'))
    } else if (!validatePhone(value)) {
        callback(new Error('手机号格式错误'))
    } else {
        callback()
    }
}

export default (getUser) => {

    const formRef = ref();
    const form = reactive({
        phone: '',
        phoneCode: ''
    })
    const rules = reactive({
        phone: [{ validator: validatePhonelRule, required: true, trigger: 'blur' }],
        phoneCode: [{ required: true, message: '请输入验证码', trigger: 'blur' },],
    })
    // 发送验证码禁用计时
    const disableTime = ref(0);

    // 开始禁用计时
    function startDisableTime(duration) {
        disableTime.value = duration;
        const t = setInterval(() => {
            disableTime.value -= 1;
            if (disableTime.value === 0) {
                clearInterval(t);
            }
        }, 1000);
    }

    // 发送验证码
    async function sendCodeRequest() {
        const { code } = await withLoading(sendMessageSms, { text: '发送中...' })({ phone: form.phone, type: 1 });
        if (code !== '0') return;
        startDisableTime(60);// 开始倒计时
        ElMessage.success('已发送，请注意查收');
    }

    // 点击发送验证码
    function sendCode() {
        if (disableTime.value > 0) return;
        if (validatePhone(form.phone)) {
            sendCodeRequest();
        } else {
            ElMessage.error('请输入正确的手机号');
            return;
        }
    }

    // 提交
    const submitForm = (formEl) => {
        if (!formEl) return
        formEl.validate((valid) => {
            if (valid) {
                bingRequest();
            }
        })
    }

    // 绑定请求
    async function bingRequest() {
        const { code } = await withLoading(apiUserUpdateUserContactForm)(form);
        if (code !== '0') return;
        ElMessage.success('绑定手机号成功');
        getUser();
    }

    return { formRef, form, rules, submitForm, disableTime, sendCode }
}