/**
 * 验证手机号
 * @param {number} phoneNumber 
 * @returns {boolean}
 */
export function validatePhone(phoneNumber) {
    const regex = /^1[3456789]\d{9}$/;
    return regex.test(phoneNumber);
}

/**
 * 验证手机号
 * @param {string} email 
 * @returns {boolean}
 */
export function validateEmail(email) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
} 